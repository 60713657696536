import { IconBusinessplan } from "@tabler/icons-react";
import { standardSchemaValidator, useForm } from "@tanstack/react-form";
import {
  infiniteQueryOptions,
  queryOptions,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { z } from "zod";

import { convertPrice, unconvertPrice } from "@joy/shared-utils";

import { useValidators } from "../../hooks";
import { Word } from "../helpers";
import { request, requestFn } from "./base";
import {
  CreatePlanDocument,
  DeletePlanDocument,
  PlanAccountsDocument,
  PlanDocument,
  PlanModel,
  PlansDocument,
  UpdatePlanDocument,
} from "./operations.generated";

export const plan: Word = {
  icon: IconBusinessplan,
  article: "a",
  singular: "plan",
  plural: "plans",
};

export const plansQuery = (enable = true) =>
  infiniteQueryOptions({
    queryKey: ["plans"],
    queryFn: ({ pageParam }) =>
      request(PlansDocument, { limit: 50, cursor: pageParam || null }),
    getNextPageParam: (lastPage) => lastPage?.plans.next,
    initialPageParam: "",
    enabled: enable,
    select: (data) => data.pages.flatMap((p) => p.plans.plans),
  });

export const planQuery = (id: string) =>
  queryOptions({
    queryKey: ["plan", id],
    queryFn: () => request(PlanDocument, { id }),
    select: (data) => data.plan!,
  });

export const planAccountsQuery = (id: string) =>
  infiniteQueryOptions({
    queryKey: ["plan-accounts", id],
    queryFn: ({ pageParam }) =>
      request(PlanAccountsDocument, {
        id,
        limit: 100,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.plan?.accounts?.next,
    initialPageParam: "",
    select: (data) =>
      data.pages.flatMap((p) => p?.plan?.accounts?.accounts || []),
  });

const validation = {
  code: z.string().min(1, "Please enter a plan code"),
  amount: z.string().regex(/^\d+(\.\d\d?)?$/, "Must be a valid amount"),
  prepaid: z.string().regex(/^\d*$/, "Must be a valid number").optional(),
  model: z.enum(["tank", "account"]),
};

const createPlanFn = requestFn(CreatePlanDocument);

export const useCreatePlan = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: createPlanFn,
    onSuccess: async (result) => {
      queryClient.setQueryData(planQuery(result.createPlan.id).queryKey, {
        __typename: "Query",
        plan: result.createPlan,
      });
      await navigate({
        to: "/library/plans/$planId",
        params: { planId: result.createPlan.id },
      });
      queryClient.invalidateQueries(plansQuery());
    },
  });

  const form = useForm({
    defaultValues: {
      code: "",
      description: "",
      amount: "",
      prepaid: "",
      model: "tank" as PlanModel,
    },
    onSubmit: ({ value }) =>
      mutateAsync({
        id: crypto.randomUUID(),
        fields: {
          ...value,
          prepaid: value.prepaid ? parseInt(value.prepaid) : undefined,
          amount: convertPrice(value.amount),
        },
      }),
    onSubmitInvalid: () => reset(),
    validatorAdapter: standardSchemaValidator(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { error, form, validators };
};

const updatePlanFn = requestFn(UpdatePlanDocument);

export const useUpdatePlan = (planId: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(planQuery(planId));
  const queryClient = useQueryClient();
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: updatePlanFn,
    onSuccess: async (result) => {
      queryClient.setQueryData(planQuery(result.updatePlan.id).queryKey, {
        __typename: "Query",
        plan: result.updatePlan,
      });
      queryClient.setQueryData(plansQuery().queryKey, (existing) => {
        if (!existing) return undefined;

        return {
          ...existing,
          pages: existing.pages.map((p) => ({
            ...p,
            plans: {
              ...p.plans,
              plans: p.plans.plans.map((u) => {
                if (u.id === result.updatePlan?.id) return result.updatePlan;
                return u;
              }),
            },
          })),
        };
      });
      await navigate({ to: "/library/plans/$planId", params: { planId } });
    },
  });

  const form = useForm({
    defaultValues: {
      code: data.code || "",
      description: data.description || "",
      prepaid: data.prepaid ? data.prepaid.toString() : "",
      amount: unconvertPrice(data.amount),
      model: data.model,
    },
    onSubmit: async ({ value }) => {
      await mutateAsync({
        id: planId,
        fields: {
          ...value,
          prepaid: value.prepaid ? parseInt(value.prepaid) : undefined,
          amount: convertPrice(value.amount),
        },
      });
    },
    onSubmitInvalid: () => reset(),
    validatorAdapter: standardSchemaValidator(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { data, error, form, validators };
};

const deletePlanFn = requestFn(DeletePlanDocument);

export const useDeletePlan = (planId: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(planQuery(planId));
  const queryClient = useQueryClient();
  const { error, mutateAsync, isPending } = useMutation({
    mutationFn: deletePlanFn,
    onSuccess: async (result, { id }) => {
      if (result.deletePlan) {
        queryClient.setQueryData(plansQuery().queryKey, (existing) => {
          if (!existing) return undefined;

          return {
            ...existing,
            pages: existing.pages.map((p) => ({
              ...p,
              plans: {
                ...p.plans,
                plans: p.plans.plans.filter((u) => u.id !== id),
              },
            })),
          };
        });

        await navigate({ to: "/library/plans" });
        queryClient.removeQueries(planQuery(id));
      }
    },
  });

  return {
    data,
    error,
    isPending,
    onDelete: () => mutateAsync({ id: planId }),
  };
};
