import { IconPower } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { Setting, pageParts } from "../../components";
import { gaugeSettingsQuery, useAuth } from "../../data";
import { useTabContext } from "../../hooks";
import { SettingsDebug } from "./settings.debug";
import {
  alarmRenderer,
  buildAlarmsSetting,
  buildDigitalSetting,
  buildSonicQualitySetting,
  dataRenderer,
  digitalRenderer,
  networkRenderer,
  passwordRenderer,
  scheduleRenderer,
  sonicQualityRenderer,
  statusInfo,
  statusRenderer,
} from "./settings.renderers";

export const GaugeSettingsPanel = ({ gaugeId }: { gaugeId: string }) => {
  const { debugging } = useTabContext();
  const { hasTeamPermission } = useAuth();
  const { data } = useSuspenseQuery(gaugeSettingsQuery(gaugeId));

  const status = useMemo(() => statusInfo(data), [data]);

  return (
    <div className={pageParts.page}>
      <Setting
        title="Status"
        edit={
          hasTeamPermission("super") && data.setting?.active !== false
            ? {
                to: "/gauges/$gaugeId",
                params: { gaugeId },
                search: { action: "disable" },
                icon: IconPower,
                variant: "danger",
                text: "Deactivate Gauge",
              }
            : undefined
        }
        className="flex gap-2"
        left={status.left}
        right={status.right}
        render={statusRenderer}
      />
      {hasTeamPermission("admin") && (
        <Setting
          title="Password"
          left={
            data.response?.settings
              ? { password: data.response.settings.password }
              : null
          }
          right={
            data.setting?.settings
              ? { password: data.setting.settings.password }
              : null
          }
          render={passwordRenderer}
        />
      )}
      <Setting
        title="Schedule"
        edit={
          data.permissions.includes("install")
            ? {
                to: `/gauges/$gaugeId`,
                params: { gaugeId },
                search: { action: "schedule" },
                text: "Edit Schedule",
              }
            : undefined
        }
        left={
          data.response?.settings.schedule && {
            ...data.response.settings.schedule,
            timezone: data.response.timezone,
          }
        }
        right={
          data.setting?.settings.schedule && {
            ...data.setting.settings.schedule,
            timezone: data.setting.timezone,
          }
        }
        render={scheduleRenderer}
      />
      {data.product === "TekUltrasonic" && (
        <Setting
          title="Sonic Quality"
          edit={
            data.permissions.includes("install")
              ? {
                  to: `/gauges/$gaugeId`,
                  params: { gaugeId },
                  search: { action: "sonic" },
                  text: "Edit Sonic Quality",
                }
              : undefined
          }
          left={buildSonicQualitySetting(data.response?.settings)}
          right={buildSonicQualitySetting(data.setting?.settings)}
          render={sonicQualityRenderer}
        />
      )}
      {data.product === "TekADC" && (
        <Setting
          title="Digital"
          left={buildDigitalSetting(data.response?.settings)}
          right={null}
          render={digitalRenderer}
        />
      )}
      <Setting
        title="Alarms"
        left={buildAlarmsSetting(data.response?.settings)}
        right={buildAlarmsSetting(
          data.setting?.settings,
          data.response?.settings,
        )}
        render={alarmRenderer}
      />
      <Setting
        title="Data Logging"
        left={
          data.response?.settings
            ? {
                logger: data.response.settings.logger,
                delivery: data.response.settings.delivery,
              }
            : null
        }
        right={null}
        render={dataRenderer}
      />
      <Setting
        title="Network"
        left={
          data.response?.settings
            ? {
                apn: data.response.settings.apn,
                server: data.response.settings.server,
                control: data.response.settings.control,
                network: data.response.settings.network,
                fallbackPhone: data.response.settings.fallbackPhone,
              }
            : null
        }
        right={null}
        render={networkRenderer}
      />
      {debugging && (
        <SettingsDebug
          gaugeId={gaugeId}
          items={data.response?.items}
          additionalSettings={data.setting?.additionalSettings}
        />
      )}
    </div>
  );
};
