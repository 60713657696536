import {
  Button,
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  ComboboxProps,
} from "@headlessui/react";
import {
  IconCheck,
  IconChevronDown,
  IconExclamationCircle,
  IconX,
} from "@tabler/icons-react";
import { Accessor, rankItem } from "@tanstack/match-sorter-utils";
import { FieldApi } from "@tanstack/react-form";
import clsx from "clsx";
import { ReactNode, Ref, useMemo, useState } from "react";

import { tw } from "@joy/shared-utils";

import { Progress } from "../navigation";
import { transitions } from "../transitions";
import { FieldError } from "./error";
import { Field, Label, fieldKinds } from "./parts";
import { TextInputProps, inputKinds, inputParts, inputVariants } from "./text";

export type ComboBaseProps<D> = TextInputProps & {
  id?: string;
  placeholder?: string;
  empty?: string;
  autoFocus?: boolean;
  suffix?: ReactNode;
  openButton?: boolean;
  optionKey?: (option: NonNullable<D>) => string | number;
  optionLabel?: (option: NonNullable<D>) => string;
  optionCreate?: (query: string) => NonNullable<D>;
  invalid?: boolean;
  loading?: boolean;
  container?: {
    ref: Ref<HTMLDivElement | null>;
    itemRef: Ref<HTMLDivElement | null>;
    className?: string;
  };
};

type ComboLookupProps<D> = {
  filtered: NonNullable<D>[];
  query: string;
  setQuery: (query: string) => void;
};

const optionParts = {
  option: tw`group flex cursor-pointer items-center gap-2 rounded-md px-3 py-2 select-none data-focus:bg-gray-300/20`,
  empty: tw`flex items-center gap-2 rounded-md px-3 py-2.5 text-sm text-gray-500 italic`,
  icon: tw`invisible size-4 shrink-0 fill-white group-data-selected:visible`,
  text: tw`truncate`,
};

const defaultKey = <D,>(o: D) => `${o}`;

const defaultBy =
  <D, M extends boolean>({
    optionKey = defaultKey,
  }: Pick<ComboBaseProps<D>, "optionKey">): NonNullable<
    ComboboxProps<D, M>["by"]
  > =>
  (a, b) =>
    (a && optionKey(a)) === (b && optionKey(b));

export const ComboBase = <D, M extends boolean>({
  id,
  className,
  icon: Icon,
  kind = "standard",
  variant = "action",
  placeholder,
  empty,
  autoFocus,
  openButton = true,
  suffix,
  optionKey = defaultKey,
  optionLabel = defaultKey,
  optionCreate,
  invalid,
  loading,
  container,
  filtered,
  query,
  setQuery,
  by,
  ...props
}: ComboLookupProps<D> & ComboBaseProps<D> & ComboboxProps<D, M>) => (
  <Combobox by={by || defaultBy({ optionKey })} {...props}>
    <div
      className={clsx(
        inputKinds[kind],
        inputVariants[variant],
        invalid && inputVariants.error,
        className,
      )}
    >
      {props.multiple && Array.isArray(props.value) && !!props.value.length && (
        <div className="mt-3 flex w-full flex-col items-start gap-1.5">
          {props.value.map((v) => (
            <div
              key={optionKey(v)}
              className="flex max-w-full items-center gap-1 rounded-sm border-0 bg-slate-200 px-2 py-1"
              title={optionLabel(v)}
            >
              {optionLabel(v)}
              <Button
                onClick={() =>
                  props.multiple &&
                  Array.isArray(props.value) &&
                  props.onChange &&
                  props.onChange(
                    props.value.filter(
                      (i) => optionKey(i) !== optionKey(v),
                    ) as any,
                  )
                }
              >
                <IconX className="size-5 text-gray-600" />
              </Button>
            </div>
          ))}
        </div>
      )}
      {Icon && <Icon className={inputParts.icon} />}
      <ComboboxInput
        id={id}
        className={clsx(inputParts.wrapped, "min-w-4 text-left")}
        displayValue={(o: D) => (o ? optionLabel(o) : "")}
        value={props.multiple ? query : undefined}
        onChange={(event) => setQuery(event.target.value)}
        onBlur={() => setQuery("")}
        placeholder={placeholder}
        autoFocus={autoFocus}
      />
      {invalid && (
        <IconExclamationCircle
          className={clsx(inputParts.icon, "text-red-700")}
        />
      )}
      {suffix}
      {openButton && (
        <ComboboxButton>
          <IconChevronDown className={inputParts.icon} />
        </ComboboxButton>
      )}
    </div>
    <ComboboxOptions
      transition
      anchor="bottom"
      className={clsx(
        "relative z-20 w-[var(--input-width)] overflow-auto rounded-md border-0 bg-white ring-1 shadow-md ring-gray-200 transition-colors [--anchor-gap:4px] [--anchor-max-height:20rem] ring-inset focus:outline-hidden",
        transitions.fade,
        container?.className,
      )}
      ref={container?.ref}
    >
      {filtered.map((option) => (
        <ComboboxOption
          key={optionKey(option)}
          value={option}
          className={optionParts.option}
        >
          <IconCheck className={optionParts.icon} />
          <div className={optionParts.text} title={optionLabel(option)}>
            {optionLabel(option)}
          </div>
        </ComboboxOption>
      ))}
      <div ref={container?.itemRef} />
      {optionCreate &&
        query.length > 0 &&
        !filtered.find((i) => optionLabel(i) === query) && (
          <ComboboxOption
            value={optionCreate(query)}
            className={optionParts.option}
          >
            <IconCheck className={optionParts.icon} />
            <div className={optionParts.text}>Use "{query}"</div>
          </ComboboxOption>
        )}
      {empty && filtered.length === 0 && !optionCreate ? (
        <div className={optionParts.empty}>{empty}</div>
      ) : null}
      <Progress
        show={loading}
        position="sticky bottom-0"
        background="bg-gradient-to-t from-sky-600/40"
      />
    </ComboboxOptions>
  </Combobox>
);

export type ComboInputProps<D> = ComboBaseProps<D> & {
  options: NonNullable<D>[];
  accessors: Accessor<NonNullable<D>>[];
};

export const ComboInput = <D, M extends boolean>({
  options,
  accessors,
  onChange,
  ...rest
}: ComboInputProps<D> & ComboboxProps<D, M>) => {
  const [query, setQuery] = useState("");
  const filtered = useMemo(
    () =>
      options
        .map((o) => {
          const { passed, rank } = rankItem(o, query, {
            accessors,
            threshold: 2,
          });

          return { o, rank, passed };
        })
        .filter((r) => r.passed)
        .sort((a, b) => a.rank - b.rank)
        .map((r) => r.o),
    [options, query],
  );

  return (
    <ComboBase
      filtered={filtered}
      setQuery={setQuery}
      query={query}
      onChange={(value) => {
        const by = rest.by || defaultBy({ optionKey: rest.optionKey });
        if (
          rest.multiple &&
          rest.optionCreate &&
          Array.isArray(value) &&
          typeof by === "function" &&
          value.find((a) => !options.find((b) => by(a, b)))
        ) {
          setQuery("");
        }
        onChange?.(value);
      }}
      {...rest}
    />
  );
};

export const ComboInputField = <D, M extends boolean>({
  field,
  ...props
}: {
  field: FieldApi<any, any, any, any, M extends true ? D[] : D>;
} & ComboInputProps<D> &
  ComboboxProps<D, M>) => (
  <ComboInput
    id={field.name}
    name={field.name}
    value={field.state.value as ComboboxProps<D, M>["value"]}
    onChange={(e) => field.handleChange(e as M extends true ? D[] : D)}
    invalid={field.state.meta.errors.length > 0}
    {...props}
  />
);

export const ComboField = <D, M extends boolean = false>({
  field,
  label,
  fieldKind,
  hint,
  ...rest
}: {
  field: FieldApi<any, any, any, any, M extends true ? D[] : D>;
  label: string;
  fieldKind?: keyof typeof fieldKinds;
  hint?: ReactNode;
} & ComboInputProps<D> &
  ComboboxProps<D, M>) => (
  <Field kind={fieldKind}>
    <Label htmlFor={field.name.toString()}>{label}</Label>
    <ComboInputField field={field} {...rest} />
    <FieldError field={field} />
    {hint && (
      <Label kind="hint" htmlFor={field.name.toString()}>
        {hint}
      </Label>
    )}
  </Field>
);
