import { createFileRoute } from "@tanstack/react-router";
import { isAfter, subDays } from "date-fns";
import { z } from "zod";

import { toTitle } from "@joy/shared-utils";

import {
  TablePage,
  actionCell,
  cellKinds,
  dateCell,
  stackCell,
} from "../../../components";
import { listLoader, user, usersQuery } from "../../../data";
import { useTable } from "../../../hooks";
import { UsersActionPanel } from "../../../panels";

const validateSearch = z.object({
  action: z.enum(["invite"]).optional(),
  inviteId: z.string().optional(),
});

export const Route = createFileRoute("/_admin/users/")({
  validateSearch,
  loader: async ({ context }) => {
    await listLoader(context.queryClient, usersQuery());
  },
  component: Component,
});

export function Component() {
  const users = useTable(usersQuery(), {
    columnDefs: (c) => [
      c.accessor(
        "name",
        stackCell({
          header: "Name",
          detail: (data) => [[toTitle(data.jlteam || "user"), data.email]],
        }),
      ),
      c.accessor("jlteam", {
        header: "Role",
        cell: (c) => toTitle(c.getValue() || "user"),
        meta: { className: cellKinds.appearsxl },
      }),
      c.accessor("email", {
        header: "Email",
        meta: { className: cellKinds.appearsxl },
      }),
      c.accessor(
        "createdAt",
        dateCell({
          header: "Created",
          kind: "appears",
        }),
      ),
      c.accessor(
        "lastActiveAt",
        dateCell({
          header: "Last Active",
        }),
      ),
      c.display(
        actionCell({
          select: (user) => ({
            to: "/users/$userId",
            params: { userId: user.id },
          }),
        }),
      ),
    ],
    select: (userId) => ({ to: "/users/$userId", params: { userId } }),
    statLocation: "header",
    statFns: [
      (filtered) => ({
        label: "Active",
        value: filtered.filter(
          (f) =>
            f.lastActiveAt && isAfter(f.lastActiveAt, subDays(new Date(), 30)),
        ).length,
      }),
    ],
    create: {
      to: "/users",
      text: "Invite user",
      search: { action: "invite" },
      replace: true,
    },
    word: user,
  });

  return <TablePage {...users.page} panel={<UsersActionPanel />} />;
}
