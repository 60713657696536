import { IconInfoCircle } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";

import {
  CheckInput,
  ComboField,
  Field,
  Label,
  ListField,
  SlideContent,
  TextField,
  fieldParts,
} from "../../components";
import {
  RoleEntityType,
  account,
  accountsQuery,
  customer,
  customersQuery,
  permissionFields,
  tank,
  tanksQuery,
  useAuth,
  useCreateRole,
  userQuery,
  user as userWord,
  usersQuery,
} from "../../data";
import { useComboQuery } from "../../hooks";

const entityTypeLabel = {
  tank: "Tank",
  account: "Account",
  customer: "Customer",
};

export const RoleAddContent = ({
  entityId,
  entityType,
  userId,
}: {
  entityId?: string;
  entityType?: RoleEntityType;
  userId?: string;
}) => {
  const { hasTeamPermission } = useAuth();
  const user = useQuery(userQuery(userId));
  const tanks = useComboQuery(tanksQuery(!!userId), { word: tank });
  const accounts = useComboQuery(accountsQuery(!!userId), { word: account });
  const customers = useComboQuery(customersQuery(!!userId), { word: customer });
  const users = useComboQuery(
    usersQuery(hasTeamPermission("admin") && !userId),
    { word: userWord },
  );
  const { error, form, validators } = useCreateRole({
    entityId,
    entityType,
    userEmail: user.data?.email,
  });
  const entityTypeOptions = {
    tank: tanks.combo,
    account: accounts.combo,
    customer: customers.combo,
  };

  return (
    <SlideContent
      title="Assign User"
      description="Setup a user's access below."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Adding..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Add", type: "submit", variant: "action" },
      ]}
    >
      {userId ? (
        <>
          <form.Field
            name="entityType"
            validators={validators.entityType}
            children={(field) => (
              <ListField
                field={field}
                label="Entity Type"
                options={validators.entityType.onSubmit.options}
                optionLabel={(o) => entityTypeLabel[o]}
              />
            )}
          />
          <form.Subscribe
            selector={(v) => v.values.entityType}
            children={(entityType) => (
              <form.Field
                name="entity"
                validators={validators.entity}
                children={(field) => (
                  <ComboField
                    field={field}
                    label={entityTypeLabel[entityType]}
                    accessors={[(o) => o.name]}
                    optionKey={(o) => o.id}
                    optionLabel={(o) => o.name}
                    immediate
                    {...entityTypeOptions[entityType]}
                  />
                )}
              />
            )}
          />
        </>
      ) : hasTeamPermission("admin") ? (
        <form.Field
          name="user"
          validators={validators.user}
          children={(field) => (
            <ComboField
              field={field}
              label="User"
              hint={
                <div className="flex items-start gap-1">
                  <IconInfoCircle className="size-5 flex-none" />
                  When you invite a new user, they'll receive an email with
                  instructions on how to access their dashboard so they can
                  begin viewing their tanks and gauges straight away.
                </div>
              }
              accessors={[(o) => o.email]}
              optionKey={(o) => o.id}
              optionLabel={(o) => o.email}
              optionCreate={(value) => ({
                id: value,
                email: value,
              })}
              immediate
              {...users.combo}
            />
          )}
        />
      ) : (
        <form.Field
          name="user.email"
          validators={validators.email}
          children={(field) => (
            <TextField
              field={field}
              label="Email address"
              hint={
                <div className="flex items-start gap-1">
                  <IconInfoCircle className="size-5 flex-none" />
                  When you invite a new user, they'll receive an email with
                  instructions on how to access their dashboard so they can
                  begin viewing their tanks and gauges straight away.
                </div>
              }
              inputMode="email"
              autoFocus
            />
          )}
        />
      )}
      <Field>
        <Label>Permissions</Label>
        <form.Subscribe
          selector={(v) => v.values.entityType}
          children={(entityType) => (
            <form.Field
              name="permissions"
              children={(field) => (
                <div className={fieldParts.checks}>
                  {permissionFields
                    .filter(({ visible }) => visible.includes(entityType))
                    .map(({ name, label, description, disabled, includes }) => (
                      <CheckInput
                        key={name}
                        label={label}
                        description={description(entityType)}
                        disabled={disabled(field.state.value)}
                        checked={!!field.state.value[name]}
                        onChange={(checked) => {
                          const updated = { ...field.state.value };
                          if (checked) {
                            updated[name] = true;
                            for (const include of includes) {
                              updated[include] = true;
                            }
                          } else {
                            updated[name] = false;
                          }
                          field.setValue(updated);
                        }}
                      />
                    ))}
                </div>
              )}
            />
          )}
        />
      </Field>
    </SlideContent>
  );
};
