import { IconCirclePlus, IconTrashX } from "@tabler/icons-react";

import {
  Button,
  ComboField,
  Field,
  Label,
  ListInputField,
  RangeInputField,
  SlideContent,
} from "../../components";
import {
  defaultAlertLevel,
  defaultAlertRate,
  tankRolesQuery,
  useUpdateTankAlerts,
  user,
} from "../../data";
import { useComboQuery } from "../../hooks";

export const TankAlertsContent = ({ tankId }: { tankId: string }) => {
  const users = useComboQuery(tankRolesQuery(tankId), { word: user });
  const { data, error, form, validators } = useUpdateTankAlerts(tankId);

  return (
    <SlideContent
      title={`Update ${data.name} Reports`}
      description="Update and save the tank report settings below."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Update", type: "submit", variant: "action" },
      ]}
    >
      <form.Field
        name="alertLevels"
        mode="array"
        children={(list) => (
          <Field>
            <Label>Alert Levels</Label>
            <div className="text-sm">
              {list.state.value.map((_, i) => (
                <div
                  key={i}
                  className="mb-6 flex flex-wrap items-center gap-2 border-l-2 pb-2 pl-2"
                >
                  <p>If the volume is</p>
                  <form.Field
                    name={`alertLevels[${i}].polarity`}
                    children={(field) => (
                      <ListInputField
                        className="flex-none"
                        field={field}
                        options={["above", "below"] as const}
                      />
                    )}
                  />
                  <div className="flex-1" />
                  <Button
                    kind="link"
                    variant="danger"
                    icon={IconTrashX}
                    onClick={() => list.removeValue(i)}
                  />
                  <form.Subscribe
                    selector={(f) => f.values.alertLevels[i]?.polarity}
                    children={(polarity) => (
                      <form.Field
                        name={`alertLevels[${i}].threshold`}
                        children={(field) => (
                          <RangeInputField
                            field={field}
                            min={0}
                            max={100}
                            stepSize={1}
                            tickSize={25}
                            colour={{
                              text: "text-sky-500",
                              bg: "bg-sky-500",
                              position:
                                polarity === "above" ? "after" : "before",
                            }}
                            valueLabel={(v) => `${v}%`}
                          />
                        )}
                      />
                    )}
                  />
                </div>
              ))}
              {list.state.value.length < 3 && (
                <Button
                  kind="link"
                  icon={IconCirclePlus}
                  text="Add Alert Level"
                  onClick={() => list.pushValue(defaultAlertLevel)}
                />
              )}
            </div>
          </Field>
        )}
      />
      <form.Field
        name="alertRates"
        mode="array"
        children={(list) => (
          <Field>
            <Label>Alert Rates</Label>
            <div className="text-sm">
              {list.state.value.map((_, i) => (
                <div
                  key={i}
                  className="mb-6 flex flex-wrap items-center gap-2 border-l-2 pb-2 pl-2"
                >
                  <p>If the volume</p>
                  <form.Field
                    name={`alertRates[${i}].polarity`}
                    children={(field) => (
                      <ListInputField
                        className="flex-none"
                        field={field}
                        options={["rising", "falling"] as const}
                        optionLabel={(o) =>
                          o === "rising" ? "rises" : "falls"
                        }
                      />
                    )}
                  />
                  <p>by</p>
                  <div className="flex-1" />
                  <Button
                    kind="link"
                    variant="danger"
                    icon={IconTrashX}
                    onClick={() => list.removeValue(i)}
                  />
                  <form.Field
                    name={`alertRates[${i}].threshold`}
                    children={(field) => (
                      <RangeInputField
                        field={field}
                        min={0}
                        max={100}
                        stepSize={1}
                        tickSize={25}
                        colour={{
                          text: "text-sky-500",
                          bg: "bg-sky-500",
                          position: "before",
                        }}
                        valueLabel={(v) => `${v}%`}
                      />
                    )}
                  />
                  <form.Field
                    name={`alertRates[${i}].period`}
                    children={(field) => (
                      <ListInputField
                        field={field}
                        options={[15, 30, 45, 60]}
                        optionLabel={(o) => `over ${o} minutes`}
                      />
                    )}
                  />
                </div>
              ))}
              {list.state.value.length < 2 && (
                <Button
                  kind="link"
                  icon={IconCirclePlus}
                  text="Add Alert Rate"
                  onClick={() => list.pushValue(defaultAlertRate)}
                />
              )}
            </div>
          </Field>
        )}
      />
      <form.Field
        name="alertUsers"
        validators={validators.alertUsers}
        children={(field) => (
          <ComboField
            field={field}
            by={(a, b) => a?.email === b?.email}
            label="Notification Emails"
            accessors={[(o) => o.email]}
            optionKey={(o) => o.id}
            optionLabel={(o) => o.email}
            optionCreate={(value) => ({ id: value, email: value })}
            multiple
            immediate
            {...users.combo}
          />
        )}
      />
    </SlideContent>
  );
};
