import { IconTemperatureSun } from "@tabler/icons-react";
import { useInfiniteQuery, useSuspenseQuery } from "@tanstack/react-query";
import { subDays } from "date-fns";
import { useEffect } from "react";

import {
  displayPercent,
  displaySignal,
  displayTemperature,
  displayVolume,
} from "@joy/shared-utils";

import {
  Box,
  Chart,
  Gauge,
  displayBattery,
  displaySignalStrength,
  pageParts,
} from "../../components";
import { IndicatorRow } from "../../components/indicators";
import { tankLevelStyle, tankLevelsQuery, tankQuery } from "../../data";
import { useChart } from "../../hooks";

export const TankLevelsPanel = ({ tankId }: { tankId: string }) => {
  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery(tankLevelsQuery(tankId, 28));
  const {
    data: { level, tankProduct, unit, alertLevels },
  } = useSuspenseQuery(tankQuery(tankId));
  const style = tankLevelStyle(level?.percent, tankProduct);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (hasNextPage && !isFetchingNextPage)
      timeout = setTimeout(() => fetchNextPage(), 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [hasNextPage, isFetchingNextPage]);

  const { indicators, series, tooltip } = useChart({
    data,
    latest: level,
    deps: [unit],
    elements: (c) => [
      c.accessor((d) => d.percent, {
        label: "Volume",
        default: true,
        variant: "emerald",
        type: "area",
        className: "divide-x divide-gray-300",
        labelFormat: (value) => (
          <span className="pr-1 font-semibold">{displayPercent(value)}</span>
        ),
        format: (_, data) => (
          <span className="pl-1">{displayVolume(data.value, unit)}</span>
        ),
        icon: undefined,
      }),
      c.accessor((d) => d.signal, {
        label: "Signal",
        variant: "purple",
        format: (value) => displaySignal(value),
        icon: (value) => displaySignalStrength(value),
        chartValue: (value) => (value ? (value / 30) * 100 : undefined),
      }),
      c.accessor((d) => d.temperature, {
        label: "Temperature",
        variant: "red",
        format: (value) => displayTemperature(value),
        icon: <IconTemperatureSun />,
      }),
      c.accessor((d) => d.voltage, {
        label: "Battery",
        variant: "sky",
        format: (value) => displayPercent(value),
        icon: (value) => displayBattery(value),
      }),
      c.accessor((d) => d.rssi, {
        label: "RSSI",
        teamPermission: "admin",
        variant: "indigo",
        format: (value) => value,
        icon: undefined,
      }),
      c.accessor((d) => d.src, {
        label: "SRC",
        teamPermission: "admin",
        variant: "slate",
        format: (value) => value,
        icon: undefined,
      }),
    ],
  });

  return (
    <div className={pageParts.page}>
      <Box header={{ title: "Current Status" }}>
        <Gauge
          value={level?.value}
          maximum={level?.maximum}
          unit={unit}
          alertLevels={alertLevels}
          {...style}
        />
        <IndicatorRow indicators={indicators} />
      </Box>
      <Box header={{ title: "History" }}>
        <Chart
          xScale={{
            type: "time",
            clamp: true,
          }}
          yScale={{ type: "linear", clamp: true, domain: [0, 100] }}
          yFormat={(d) => `${d}%`}
          data={data}
          loading={isLoading}
          mock={() => {
            const end = new Date();
            return [
              {
                id: "",
                maximum: 0,
                percent: 20,
                value: 0,
                temperature: 13,
                updatedAt: subDays(end, 14),
              },
              {
                id: "",
                maximum: 0,
                percent: 60,
                value: 0,
                temperature: 15,
                updatedAt: subDays(end, 11),
              },
              {
                id: "",
                maximum: 0,
                percent: 50,
                value: 0,
                temperature: 17,
                updatedAt: subDays(end, 6),
              },
              {
                id: "",
                maximum: 0,
                percent: 85,
                value: 0,
                temperature: 18,
                updatedAt: subDays(end, 2),
              },
              {
                id: "",
                maximum: 0,
                percent: 80,
                value: 0,
                temperature: 18,
                updatedAt: end,
              },
            ];
          }}
          series={series}
          tooltip={tooltip}
        />
      </Box>
    </div>
  );
};
