import { useMatch } from "@tanstack/react-router";

import { ActionsMatch } from "../../components";
import { AccountCreateContent } from "./create";

export const AccountsActionPanel = () => {
  const actions = useMatch({
    from: "/_user/accounts/",
    select: (s) => {
      const props = {};

      switch (s.search.action) {
        case "create":
          return { Slide: AccountCreateContent, props };
      }

      return { props };
    },
  });

  return <ActionsMatch actions={actions} />;
};
